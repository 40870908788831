<template>
    <b-modal
        id="modalPasswordChange"
        :title="$t('user.password_change')"
        size="sm"
        :ok-title="$t('form.save')"
        :cancel-title="$t('form.cancel')"
        header-bg-variant="primary"
    >
        <b-form-group
            label-size="md"
            label-class="font-weight-bold pt-0"
            class="mb-0"
        >
            <!-- old password -->
            <b-form class="mb-3">
                <label class="mr-2">{{$t('user.password_old')}}</label>
                <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    id="profile_password_old"
                    type="password"
                    autocomplete="false"
                    required
                ></b-form-input>
            </b-form>
            <!-- new password -->
            <b-form class="mb-3">
                <label class="mr-2">{{$t('user.password_new')}}</label>
                <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    id="profile_password_new"
                    :type="passwordShow ? 'text' : 'password'"
                    required
                ></b-form-input>
                <!-- checkbox -->
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="passwordShow"
                    name="checkbox_password_show"
                >
                    {{$t('user.password_show')}}
                </b-form-checkbox>
            </b-form>
        </b-form-group>
    </b-modal>
</template>

<script>
export default {
    name: "ModalChangePassword",
    components: {},
    props: {},
    data() {
        return {
            passwordShow: false,
        }
    },
    mounted() {},
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
