<template>
    <div>
        <b-row>
            <b-col>
                <!-- email, username -->
                <b-form-group
                    :label="$t('user.settings')"
                    class="mb-0"
                    label-class="font-weight-bold pt-0"
                    label-size="md"
                >
                    <!-- email -->
                    <b-form-group
                        :label="$t('user.email')"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        description=""
                        label-class="mb-0"
                        label-for="profile.email"
                    >
                        <b-form-input
                            id="profile.email"
                            v-model="record.email"
                            :placeholder="$t('user.email_placeholder')"
                            class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                            required
                            type="email"
                        ></b-form-input>
                        <!--                            readonly    &lt;!&ndash;TODO email is readonly if you are not admin&ndash;&gt;-->
                    </b-form-group>
                    <!-- username -->
                    <b-form-group
                        :label="$t('user.username')"
                        class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                        description=""
                        label-class="mb-0"
                        label-for="profile.username"
                    >
                        <b-form-input
                            id="profile.username"
                            v-model="record.username"
                            :placeholder="$t('user.username_placeholder')"
                            class="mb-2 mr-sm-2 mb-sm-0"
                        ></b-form-input>
                    </b-form-group>
                </b-form-group>
            </b-col>

            <b-col>
                <!-- avatar -->
                <form-avatar></form-avatar>
                <!-- change password-->
                <b-form-group
                    :label="$t('user.password_change_question')"
                    class="mb-0"
                    disabled
                    label-class="font-weight-bold pt-0"
                    label-size="md"
                >
                    <b-button @click="$bvModal.show('modalPasswordChange')">
                        {{$t('user.password_change_button')}}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
        <!-- modalChangePassword -->
        <modal-change-password></modal-change-password>
    </div>
</template>

<script>
import ModalChangePassword from "@/components/authentication/user/ModalChangePassword";
import FormAvatar from "@/components/general/elements/pictures/avatar/FormAvatar";

export default {
    name: "FormProfile",
    components: {FormAvatar, ModalChangePassword},
    props: {
        record: {}
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
