<template>
    <div class="min-vh-100 p-3">
        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <!-- form profile -->
            <form-profile></form-profile>
            <!-- toolbar form -->
            <b-button @click="baseUpdate()">Save</b-button>
        </b-overlay>
    </div>
</template>

<script>
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";
import ModalFormProfile from "@/components/manager/profile/ModalFormProfile_BRISI";
import FormProfile from "@/components/manager/profile/FormProfile";

export default {
    name: "Profile",
    components: {FormProfile, ModalFormProfile, TableToolbar},
    props: {},
    data() {
        return {
/*            columns: [
                {
                    key: 'avatar',
                    label: this.$t('profile.avatar'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'name',
                    label: this.$t('profile.name'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'surname',
                    label: this.$t('profile.surname'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'agreement',
                    label: this.$t('profile.agreement'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'phone',
                    label: this.$t('profile.phone'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'address',
                    label: this.$t('profile.address'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'edit',
                    label: this.$t('form.edit'),
                    sortable: false,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
            ],
            items: [
                {
                    id: 5,
                    avatar: 'slika',
                    name: 'LeAnn',
                    surname: 'Colbert',
                    agreement: null,
                    phone: '0038641555555',
                    address: 'Kočevarjeva 19, 2000 Maribor',
                },
            ],
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },*/

            user_id: 2, // TODO temporary value for development

            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: {
                // main: `api/v1/admin/users`,
                // uri1: `api/v1/admin/user`,
            },
            record: {},
        }
    },
    mounted() {
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- api calls -->
        baseSelect: function () {
            console.log("baseSelect"); // TODO brisi
            // this.showOverlay(true);
            // this.axios.get(this.uri.main)
            //     .then((response) => {
            //         this.$set(this, 'data', response.data);
            //         this.showOverlay(false);
            //     })
            //     .catch((error) => {
            //         // console.log("ERROR:", error.response.data);
            //     });
        },
        baseUpdate() {
            console.log("baseUpdate"); // TODO brisi
            // this.showOverlay(true);
            // // <--  -->
            // let record = this.record;
            // // <-- clear errors -->
            // this.resetErrors();
            // // <-- api -->
            // this.axios.put(this.uri.main + `/${this.record.id}`,
            //     {
            //         'locale': this.$i18n.locale,
            //         'record': record
            //     })
            //     .then((response) => {
            //         this.hideModal(response.data);
            //         this.showOverlay(false);
            //     })
            //     .catch((error) => {
            //         error = error.response.data;
            //         this.errors.message = error;
            //         this.showOverlay(false);
            //     });
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
