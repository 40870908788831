<template>
    <b-modal
        id="modalFormProfile"
        :title="$tc('profile.profile', 1)"
        size="xl"
        :ok-title="$t('form.save')"
        :cancel-title="$t('form.cancel')"
        header-bg-variant="primary"
        footer-bg-variant=""
    >
        <!-- tabs -->
        <b-card no-body>
            <b-tabs pills card vertical>
                <!-- personal info -->
                <b-tab :title="$t('tab.personal')">
                    <b-card-text>
                        <b-row>
                            <!-- personal -->
                            <b-col>
                                <b-form-group
                                    :label="$t('profile.personal_data')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <!-- name -->
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('profile.name')}}:</label>
                                        <b-form-input
                                            v-model="record.profile.name"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_name"
                                            type="text"
                                            :placeholder="$t('placeholder.name')"
                                        ></b-form-input>
                                    </b-form>
                                    <!-- surname -->
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('profile.surname')}}:</label>
                                        <b-form-input
                                            v-model="record.profile.surname"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_surname"
                                            type="text"
                                            :placeholder="$t('placeholder.surname')"
                                        ></b-form-input>
                                    </b-form>
                                    <!-- phone -->
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('profile.phone')}}:</label>
                                        <b-form-input
                                            v-model="record.profile.phone"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_phone"
                                            type="text"
                                            :placeholder="$t('placeholder.phone')"
                                        ></b-form-input>
                                    </b-form>
                                    <!-- status -->
                                    <b-form class="mb-3">
                                        <label
                                            class="mr-2 font-weight-bold text-secondary">{{$t('profile.status')}}:</label>
                                        <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_status"
                                            v-model="record.personal.status"
                                            :options="data.status"
                                        ></b-form-select>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                            <!--address-->
                            <b-col>
                                <b-form-group
                                    :label="$t('profile.address')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('profile.state')}}:</label>
                                        <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_state"
                                            v-model="record.contact.state"
                                            :options="data.state"
                                        ></b-form-select>
                                    </b-form>
                                    <b-form class="mb-3" v-if="record.contact.state===-1">
                                        <label class="mr-2">{{$t('profile.address')}}:</label>
                                        <b-form-textarea
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_address_other"
                                            v-model="record.contact.addressFull"
                                            :placeholder="$t('organisation.address_placeholder')"
                                        ></b-form-textarea>
                                    </b-form>
                                    <b-form-group v-else="record.contact.state===-1">
                                        <b-form class="mb-3">
                                            <label class="mr-2">{{$t('profile.address')}}:</label>
                                            <b-form-input
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="profile_street"
                                                v-model="record.contact.address"
                                                :placeholder="$t('organisation.street_placeholder')"
                                            ></b-form-input>
                                        </b-form>
                                        <b-form class="mb-3">
                                            <label class="mr-2">{{$t('profile.post')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="profile_post"
                                                v-model="record.contact.post"
                                                :options="data.post"
                                            ></b-form-select>
                                        </b-form>
                                    </b-form-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>

                <!-- profile settings -->
                <b-tab :title="$t('tab.profile')">
                    <b-card-text>
                        <b-row>
                            <b-col>
                                <!-- email, username -->
                                <b-form-group
                                    :label="$t('profile.settings')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('profile.email')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_email"
                                            v-model="record.contact.email"
                                            type="email"
                                            required
                                            readonly
                                            :placeholder="$t('placeholder.email')"
                                        ></b-form-input>
                                    </b-form>
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('profile.username')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="profile_username"
                                            v-model="record.contact.username"
                                            type="email"
                                            required
                                            :placeholder="$t('placeholder.username')"
                                        ></b-form-input>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <!-- change password -->
                                <b-form-group
                                    :label="$t('profile.password_change_question')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <b-button @click="$bvModal.show('modalPasswordChange')">
                                        {{$t('profile.password_change_button')}}
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>

                <!-- image -->
                <b-tab :title="$t('tab.profile_picture')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('tab.profile_picture')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                            Slika profila
                        </b-form-group>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <!-- modalChangePassword -->
        <modal-change-password></modal-change-password>
    </b-modal>
</template>

<script>

import ModalChangePassword from "@/components/authentication/user/ModalChangePassword";
export default {
    name: "modalFormProfile",
    components: {},
    props: {
        form: {
            valid: false,
            record: {},
        },
        modalBus: Object,
    },
    data() {
        return {
            valid: null,
            data: {
                status: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Active',
                    },
                    {
                        value: 2,
                        text: 'Probation',
                    },
                    {
                        value: 3,
                        text: 'Blocked',
                    },
                ],
                state: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Slovenija',
                    },
                    {
                        value: -1,
                        text: 'Drugo',
                    },
                ],
                post: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: '9244 Sveti Jurij ob Ščavnici',
                    },
                    {
                        value: 2,
                        text: '2000 Maribor',
                    },
                    {
                        value: 3,
                        text: '8000 Murska Sobota',
                    },
                ],
            },
            record: {
                id: 0,
                profile: {
                    name: null,
                    surname: null,
                    phone: null,
                },
                personal: {
                    status: null,
                },
                contact: {
                    state: null,
                    post: null,
                    address: null,
                    addressFull: null,
                    email: null,
                    username: null,
                },
            },
            profile_type: [],
        }
    },
    mounted() {
    },
    methods: {
        // <-- form -->
        submit() {
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();

            this.$set(this.form, 'record', this.record);
            this.$set(this.form, 'valid', this.valid);
        }
    },
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        },
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        name_full: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        valid_continent() {
            if (this.valid === null) {
                return null;
            }
            return this.record.continent_id > 0 ? null : false;
        },
        // <-- meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
        valid_profile_type() {
            // if (this.valid === null) {
            //     return null;
            // }
            // return this.record.meta.name.length > 0 ? null : false;
            return false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
</style>
